<template>
  <div>
    <md-toolbar class="md-primary mb-2" md-elevation="1">
        <h3 class="md-title" style="flex: 1">Users</h3>
        
        <md-button class="md-primary md-raised" @click="newUser">New user</md-button>
        
    </md-toolbar>
    <md-progress-bar md-mode="query" v-if="isBusy"></md-progress-bar> 
    <div v-else>

      <md-empty-state
        v-if="users.length == 0"
        md-icon="person_add"
        md-label="Add your user">
        <md-button class="md-primary md-raised" @click="newUser">Add user</md-button>
      </md-empty-state>       
      <md-table v-else v-model="users" md-sort="email" md-sort-order="asc">
        

        <md-table-row slot="md-table-row" slot-scope="{ item }" @click="rowClicked(item)">
          <md-table-cell md-label="Email" md-sort-by="email">{{item.email}}</md-table-cell>
          
          <md-table-cell md-label="Display name" md-sort-by="displayName">{{item.displayName}}</md-table-cell>
          
          <md-table-cell md-label="Role" md-sort-by="role">{{item.role ? item.role : ''}}</md-table-cell>
          <md-table-cell md-label="Subrole" md-sort-by="subrole">{{item.subrole ? item.subrole : ''}}</md-table-cell>
          <md-table-cell md-label="Code" md-sort-by="code">{{item.code ? item.code : ''}}</md-table-cell>
          <md-table-cell md-label="UID" md-sort-by="uid">{{item.uid}}</md-table-cell>
          
        </md-table-row>
      </md-table>
    </div>
  </div>
</template>

<script>

import axios from "./../../helpers/axios"

export default {
  name: 'Users',
  data () {
    return {
      users: [],
      isBusy: true
      
    }
  },
  mounted() {
    
  },
  created() {
    this.getUsers();
  },
  computed: {
    user() {
      return this.$store.state.user;
    }
      
  },
  methods: {
    newUser() {
      this.$router.push({path: `/app/users/new`})
    },
    getBadge (status) {
      switch (status) {
        case 'Active': return 'success'
        case 'Inactive': return 'secondary'
        case 'Pending': return 'warning'
        case 'Banned': return 'danger'
        default: 'primary'
      }
    },
    rowClicked (item) {
     
      this.$router.push({path: `/app/users/${item.uid}`})
    },
    pageChange (val) {
      this.$router.push({ query: { page: val }})
    },
    getToken() {

    },
    getUsers(){
      let th = this ;
      let url = null;
      
      let email = this.user.data.email ;
      let role = this.user.data.activeRole ;
      let data = {  
            ownerEmail: email
      }
      

      
      if(role == 'super-admin') {
        url = 'users';
      } else if (role == 'company-admin') {
        url = 'company-users';
      }
      
      
        
      
      axios.get(url ,{ params: data }).then(response =>{
        console.log(response);
        th.users = response.data.users ;
        th.isBusy = false;
      }).catch(e => {
        console.log(e);
        th.isBusy = false;
      })
      
          
    }
  }
}
</script>
